import Hotjar from '@hotjar/browser';

export function initHotjar() {
  if (!import.meta.env.VITE_HOTJAR_ID) {
    return;
  }

  const siteId = parseInt(import.meta.env.VITE_HOTJAR_ID, 10);
  const hotjarVersion = parseInt(import.meta.env.VITE_HOTJAR_SV, 10);

  Hotjar.init(siteId, hotjarVersion);
}
