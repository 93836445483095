import posthog from 'posthog-js';

export function initPosthog() {
  const host = import.meta.env.VITE_POSTHOG_API_HOST as string;
  const id = import.meta.env.VITE_POSTHOG_API_KEY as string;

  if (!host || !id) {
    return;
  }

  posthog.init(id, {
    api_host: host,
    // person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
    person_profiles: 'always', // or 'always' to create profiles for anonymous users as well
    capture_pageview: false,
  });
}
